var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"story-hero-container",style:(`background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(82, 94, 110, 0.9)), url(${_vm.backgroundImageUrl});`)},[_c('div',{staticClass:"container"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.titleText)}}),(_vm.heroItem.videoUrl)?_c('font-awesome-icon',{staticClass:"play-icon",attrs:{"role":"button","aria-label":"Open Video Player","icon":"play"},on:{"click":function($event){return _vm.$modal.show(_vm.modalName)}}}):_vm._e(),_c('h3',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.showSubtitle,
        once: true,
        intersection: {
          threshold: 0.3
        }
      }),expression:"{\n        callback: showSubtitle,\n        once: true,\n        intersection: {\n          threshold: 0.3\n        }\n      }"}],ref:"subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}})],1),(_vm.heroItem.videoUrl)?_c('ModalWrapper',{staticClass:"video-modal",attrs:{"modal-name":_vm.modalName,"width":"90%"}},[_c('vueVimeoPlayer',{ref:"player",staticClass:"vimeo-player",attrs:{"video-url":_vm.heroItem.videoUrl,"video-id":null,"options":_vm.vimeoOptions}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }